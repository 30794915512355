import React, { Component, Fragment, useContext } from "react"
import Slider from "../../../local_modules/react-slick.min"
import styles from "./index.global.css"
import cx from "classnames"
import { ArrowLeft, ArrowRight } from "./arrows"
import Slide from "./slide"
import objFitImages from "object-fit-images"
import { LocaleContext } from "../_layout"

const SPEED = 600

export default class Carousel extends Component {
  static contextType = LocaleContext

  state = {
    hoverNext: false,
    hoverPrev: false,
  }
  lastI = null
  lastCurrentSlide = null

  constructor(props) {
    super(props)
    this.slider = React.createRef()
    this.control = React.createRef()
  }

  shouldComponentUpdate(props, state) {
    return (
      this.state.hoverNext !== state.hoverNext ||
      this.state.hoverPrev !== state.hoverPrev ||
      this.state.slideIdx !== state.slideIdx
    )
  }

  handleEnter = (i, currentSlide) => {
    this.setState({
      hoverNext: i === currentSlide + 1,
      hoverPrev: i === currentSlide - 1,
    })
  }

  handleLeave = (i, currentSlide) => {
    this.setState({
      hoverNext: false,
      hoverPrev: false,
    })
  }

  handleAfterChange = idx => {
    this.setState({
      slideIdx: idx,
    })
  }

  componentDidMount() {
    objFitImages(document.querySelectorAll(".carouselImage"))
  }

  render() {
    const { images, initialSlide } = this.props
    const currentSlide =
      typeof this.state.slideIdx === "number"
        ? this.state.slideIdx
        : initialSlide

    const responsive = [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          focusOnSelect: false,
          variableWidth: false,
          centerPadding: "0px",
        },
      },
    ]

    return (
      <Fragment>
        <style jsx>{styles}</style>
        <style jsx global>{`
          .slick-slider {
            ${typeof window !== "undefined"
              ? "height: calc(" +
                window.innerHeight.toString() +
                "px - 3rem) !important;"
              : ""}
          }
        `}</style>
        <Slider
          ref={this.slider}
          focusOnSelect={true}
          variableWidth={true}
          nextArrow={<ArrowRight />}
          prevArrow={<ArrowLeft />}
          centerPadding="220px"
          responsive={responsive}
          speed={SPEED}
          infinite={false}
          className={cx({
            hoverNext: this.state.hoverNext,
            hoverPrev: this.state.hoverPrev,
          })}
          centerMode={true}
          initialSlide={initialSlide}
          afterChange={this.handleAfterChange}
        >
          {images.map((image, i) => (
            <Slide
              key={i}
              idx={i}
              handleEnter={this.handleEnter}
              handleLeave={this.handleLeave}
              onDismiss={this.props.onDismiss}
              currentSlide={currentSlide}
              image={image}
            />
          ))}
        </Slider>
        <button className="slick-arrow close" onClick={this.props.onDismiss}>
          {this.context === "zh" ? "关闭" : "Close"}
        </button>
      </Fragment>
    )
  }
}

import React, { PureComponent, Fragment } from "react"
import Layout from "../components/_layout"
import Gallery from "../components/Gallery"
import Articles from "../components/Articles"
import VideoIntro, { Preloader } from "../components/VideoIntro"
import Loader from "../components/Loader"
import AboutColumns from "../components/AboutColumns"
import Studios from "../components/Studios"
import Footer from "../components/Footer"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

export default class Index extends PureComponent {
  state = {
    isLoading: true,
  }

  render() {
    const { homepage, gallery, articles } = this.props.data
    return (
      <Layout lang={homepage.node_locale}>
        <Preloader
          video={homepage.video}
          onLoad={() => this.setState({ isLoading: false })}
        />
        {!this.state.isLoading ? (
          <Fragment>
            <VideoIntro locale={homepage.node_locale} video={homepage.video} />
            <div className="section-dark">
              <Gallery gallery={gallery} />
              <AboutColumns>
                {homepage.aboutText ? (
                  <ReactMarkdown source={homepage.aboutText.aboutText} />
                ) : null}
              </AboutColumns>
            </div>
            <div className="section-white">
              <Studios
                title={homepage.studiosSectionTitle}
                studios={homepage.studios}
              />
              <Articles
                locale={homepage.node_locale}
                title={homepage.articlesTitle}
                articles={articles}
              />
            </div>
            <Footer
              buttonLink={homepage.footerButtonLink}
              buttonLabel={homepage.footerButtonLabel}
              logos={homepage.footerLogos}
              leadText={homepage.footerLeadText.footerLeadText}
              footerPrivacyPolicyLabel={homepage.footerPrivacyPolicyLabel}
              footerPrivacyPolicyLink={homepage.footerPrivacyPolicyLink}
              footerCopyrightNotice={homepage.footerCopyrightNotice}
              footerLinks={homepage.footerLinks}
            />
          </Fragment>
        ) : (
          <Loader />
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String!, $locale: String) {
    homepage: contentfulHomepage(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      id
      node_locale
      video {
        file {
          url
          contentType
        }
      }
      aboutText {
        aboutText
      }
      studiosSectionTitle
      articlesTitle
      ...studios
      ...footer
    }
    articles: allContentfulArticle(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          ...article
        }
      }
    }
    gallery: contentfulGallery {
      ...gallery
    }
  }
`

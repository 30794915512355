import React from "react"
const ANIM_DURATION = 3400

export default () => (
  <div className="loader">
    <style jsx>{`
      .loader-anim {
        position: absolute;
        top: 45%;
        left: calc(50% - 100px);
        width: 200px;
      }

      @keyframes rect {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(200%);
        }
      }

      @keyframes letter {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.15;
        }
        100% {
          opacity: 1;
        }
      }

      .letter {
        animation: letter ${ANIM_DURATION}ms infinite;
        fill: #3d454d;
        transform: translate(80px, 20px);
      }

      .pre {
        animation: rect ${ANIM_DURATION / 2}ms infinite;
        fill: rgba(22, 31, 41, 1);
      }
    `}</style>

    <svg
      id="ed3da372-7b68-4861-a546-b0c9dc462f76"
      xmlns="http://www.w3.org/2000/svg"
      className="loader-anim"
      viewBox="0 0 805 95"
    >
      <path
        d="M547.7.16h-4.92c-.59,0-.84,0-.84.8V54.08c0,.76.27.79.87.79h4.93c.59,0,.83,0,.83-.79V1C548.57.19,548.3.16,547.7.16Z"
        className="letter"
      />
      <path
        d="M414.18,17.84c-.33,6.49-.33,13,0,19.49.61,12.2,8.77,17.57,19.84,17.57h33.76c.64,0,.66-.24.66-.83h0v-3c0-.6,0-.79-.75-.8,0,0-16.09.06-33.17,0-7.64,0-13-4.23-13.64-11.23-.27-2.81-.47-5.88-.52-9.35h46.41c.45,0,.47-.26.48-.84V25.49c0-.65,0-.87-.41-.87H420.37a89.17,89.17,0,0,1,.55-9.27C421.68,8.88,427.7,5,434.57,4.86h33.12c.72,0,.74-.27.74-.87V1h0c0-.58,0-.82-.66-.83h0c-.11,0-.32,0-33.74,0C425.33.17,414.89,4,414.18,17.84Z"
        className="letter"
      />
      <path
        d="M405.85,17.62C405.3,7.63,399,.16,385.47.16h-41.8c-.29,0-.54,0-.54.8V54.08c0,.76.27.79.87.79h41.49c13.51,0,19.71-7.37,20.38-17.66A166.28,166.28,0,0,0,405.85,17.62ZM399.06,39.5c-1.15,9.63-11.19,10.74-13.32,10.7-1.44.07-36,0-36,0V4.86H386.1C393,5,398.37,9.35,399,15.58A108.71,108.71,0,0,1,399.06,39.5Z"
        className="letter"
      />
      <path
        d="M271,42.82H233.71c-2.06,0-3.18-.72-3.18-2.87V1.76c0-1.57-.53-1.6-1.73-1.6H216.38c-1.21,0-1.76,0-1.76,1.6V38.57c0,12.36,4.45,16.3,16.42,16.3h40.1c1.44,0,1.46-.53,1.46-1.74V44.56C272.6,43.35,272.58,42.82,271,42.82Z"
        className="letter"
      />
      <path
        d="M163.59,12h39.32c1.6,0,1.53-.47,1.53-1.66V1.83c0-1.21.07-1.67-1.53-1.67h-40C151.81.16,145,3.64,145,15.14V39.89c0,11.51,6.77,15,17.82,15h40c1.6,0,1.53-.45,1.53-1.67V44.72c0-1.19.07-1.65-1.53-1.65H163.59c-1.69,0-2.66-.68-2.66-2.42V33.12h40.89c1.59,0,1.53-.45,1.53-1.66V23c0-1.21.06-1.66-1.53-1.66H160.93V14.39C160.93,12.65,161.9,12,163.59,12Z"
        className="letter"
      />
      <path
        d="M95.41,12h39.32c1.59,0,1.55-.47,1.55-1.66V1.83c0-1.21,0-1.67-1.55-1.67h-40c-11.07,0-17.83,3.48-17.83,15V39.89c0,11.51,6.76,15,17.83,15h40c1.59,0,1.55-.45,1.55-1.67V44.72c0-1.19,0-1.65-1.55-1.65H95.41c-1.69,0-2.64-.68-2.64-2.42V33.12h40.88c1.59,0,1.53-.45,1.53-1.66V23c0-1.21.06-1.66-1.53-1.66H92.77V14.39C92.77,12.65,93.72,12,95.41,12Z"
        className="letter"
      />
      <path
        d="M18.56,12H65c1.58,0,1.52-.47,1.52-1.66V1.83C66.49.62,66.55.16,65,.16H17.83C6.76.16,0,3.64,0,15.14V39.89c0,11.51,6.76,15,17.83,15H65c1.58,0,1.52-.45,1.52-1.67V23c0-1.21.06-1.66-1.52-1.66H26.07c-1.59,0-1.52.45-1.52,1.66v8.48c0,1.21-.07,1.66,1.52,1.66H50.6v10h-32c-1.68,0-2.64-.68-2.64-2.42V14.39C15.92,12.65,16.88,12,18.56,12Z"
        className="letter"
      />
      <path
        d="M332,.16H317.66A5.58,5.58,0,0,0,313,2L293.22,23.63,273.49,2a5.62,5.62,0,0,0-4.7-1.82H254.41c-1.19,0-1.66.53-.28,2.06L285.27,36.4V53.26c0,1.59.53,1.61,1.74,1.61h12.41c1.21,0,1.76,0,1.76-1.61V36.4L332.31,2.22C333.69.69,333.23.16,332,.16Z"
        className="letter"
      />
      <path
        d="M679.8,54.87c1.25,0,5.56,0,5.76,0s.11,0,.11-.77V.66c0-.48-.32-.5-.51-.5h-5.64a.48.48,0,0,0-.47.49V48.94a1.76,1.76,0,0,1-1.34-.62L639.82,3.24A8,8,0,0,0,634,.14h-6a.48.48,0,0,0-.45.48c0,6,0,53.46,0,53.46,0,.77.29.79.95.79h5.18a.5.5,0,0,0,.46-.51V6.1a1.75,1.75,0,0,1,1.34.61l38.16,45.43a8,8,0,0,0,4.56,2.65Z"
        className="letter"
      />
      <path
        d="M558.59,33.28c0-.18-.09-6.42-.09-6.42l.2-7.06s.42-8.44,3.8-12.71c3.66-4.62,9.07-6.91,16.38-6.93L618,.18a.44.44,0,0,1,.48.48s0,3.71,0,3.77a.5.5,0,0,1-.48.43H579.38a35.1,35.1,0,0,0-3.75.17c-8.8,1.11-9.87,8.08-10.15,12,0,.08-.27,4.64-.27,4.64L565,26.94l0,5.27c0,1.62.14,9.9,2.15,12.79,1.51,2.19,3.66,4.92,11.95,5.18l33-.06V29.65l-30.46,0a.47.47,0,0,1-.5-.46V25.08c0-.43.46-.43.66-.43s35.21,0,36.08,0,.86.27.85.76v29a.47.47,0,0,1-.5.48l-40.42,0c-7.14-.09-13.38-3.37-15.94-7.66-3.13-5.22-3.13-10.29-3.37-14"
        className="letter"
      />
      <path
        d="M518.38,29.5l-25.94,0c-6.9-.19-14.61-2.85-14.56-15.16C477.65-.32,492.23.11,493.5.08L531.36,0a.48.48,0,0,1,.46.45V4.23a.5.5,0,0,1-.41.47H495.26c-2.14.17-11.14-1-11,9.69-.11,9.52,5.15,9.83,8.64,10.07l25,0c4.1.09,15.77-.22,15.93,14.29.2,15.22-13.49,15.88-16.42,15.93H480a.47.47,0,0,1-.47-.43c0-.42,0-3.83,0-3.83A.46.46,0,0,1,480,50h35.9l1.65,0c4.52-.36,10-2.06,9.91-11C527.34,37,527.66,29.71,518.38,29.5Z"
        className="letter"
      />
      <rect className="pre" x="-685.67" y="0" width="700" height="90" />
    </svg>
  </div>
)

import { useState, useEffect } from "react"

export function useWindowMousePosition() {
  const [windowMousePosition, setWindowMousePosition] = useState({
    x: null,
    y: null,
  })

  function handleMouseMove(e) {
    setWindowMousePosition({
      x: e.clientX,
      y: e.clientY,
    })
  }

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove)

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [])

  return windowMousePosition
}

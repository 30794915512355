import React, { Fragment, useRef, useState, useEffect } from "react"
import { resolve } from "styled-jsx/css"
import Image from "gatsby-image"
import cx from "classnames"
import positions1280 from "./_responsive1280"
import positions768 from "./_responsive768"
import positions320 from "./_responsive320"
import GalleryLayout from "./gallery-layout"
import isTouchDevice from "../../lib/is-touch-device"
import Modal from "react-aria-modal"
import Carousel from "../Carousel"
import galleryStyles from "./gallery.scoped.css"
import { graphql } from "gatsby"
import { useWindowSize } from "../../hooks/use-window-dimensions"
import { useSpring, animated } from "react-spring"
import { useWindowMousePosition } from "../../hooks/use-mouse-position"
import useDeviceMotion from "../../hooks/use-device-motion"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

const inStyles = resolve`
  div {
    opacity: 1;
    transform: translateX(0);
  }`

const outStyles = resolve`
  div {
    transform: translateX(0);
  }`

const underlayStyles = resolve`
  div {
    background: rgba(22, 31, 41, 1);
    opacity: 0;
    transition: opacity 450ms 50ms, transform  450ms 50ms;
    transform: translateX(16px);
  }`

const adjustSize = (width, height, target) => {
  const area = width * height
  const divider = Math.sqrt(area / target)

  return {
    width: Math.round(width / divider),
    height: Math.round(height / divider),
  }
}
const trans1 = (depth, x, y) =>
  `translate3d(${(x / 7) * depth}px,${(y / 6) * depth}px,0)`

export default ({ gallery }) => {
  const galleryRef = useRef()
  const windowSize = useWindowSize()
  const [hasEntered, setEntered] = useState(false)
  const [isModalOpen, setisModalOpen] = useState(false)
  const [positions, setPositions] = useState(positions768)
  const [isClient, setIsClient] = useState(false)
  const [modalAction, setModalAction] = useState("in")
  const [slideClickedIdx, setSlideClickedIdx] = useState(null)
  const mousePosition = useWindowMousePosition()
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 1, tension: 440, friction: 80 },
  }))

  const { beta, gamma } = useDeviceMotion()

  const calcMotion = (beta, gamma) => [
    Math.max(Math.min(gamma * 100, window.innerWidth), -window.innerWidth),
    Math.max(Math.min(beta * 10, window.innerHeight), -window.innerHeight),
  ]

  const calc = (x, y) => [
    -x + windowSize.innerWidth / 2,
    -y + windowSize.innerHeight / 2,
  ]

  useEffect(() => {
    set({ xy: calcMotion(beta, gamma) })
  }, [beta, gamma])

  useEffect(() => {
    set({ xy: calc(mousePosition.x, mousePosition.y) })
  }, [mousePosition.x, mousePosition.y])

  useEffect(() => clearAllBodyScrollLocks, [])

  const handleEnter = () => {
    setEntered(true)
  }

  const updateDimensions = () => {
    if (window.matchMedia("(min-width: 1280px)").matches) {
      setPositions(positions1280)
    } else if (window.matchMedia("(min-width: 768px)").matches) {
      setPositions(positions768)
    } else {
      setPositions(positions320)
    }
  }

  useEffect(() => {
    updateDimensions()
    window.addEventListener("resize", updateDimensions)
    setIsClient(true)

    return () => {
      window.removeEventListener("resize", updateDimensions)
    }
  }, [])

  const getApplicationNode = () => {
    return document.getElementById("___gatsby")
  }

  const closeModal = () => {
    setModalAction("out")
    setEntered(false)
    setTimeout(() => {
      enableBodyScroll(document.querySelector(".modal"))
      setisModalOpen(false)
    }, 500)
  }
  useEffect(() => {
    if (isModalOpen) disableBodyScroll(document.querySelector(".modal"))
  }, [isModalOpen])

  const openModal = i => {
    setisModalOpen(true)
    setModalAction("in")
    setSlideClickedIdx(i)
  }

  const images = gallery.images.map(img => ({
    ...img,
    src: img.file.url,
    thumb: img.thumb,
    carousel: img.carousel,
    ...img.file.details.image,
  }))

  const galleryLayout = new GalleryLayout(images, positions)

  return (
    <>
      <style jsx>{galleryStyles}</style>
      <div
        className="gallery"
        style={{
          height: galleryLayout.getSceneHeight(),
        }}
        ref={galleryRef}
      >
        {underlayStyles.styles}
        {inStyles.styles}
        {outStyles.styles}
        {isClient ? (
          <>
            <Modal
              focusDialog={true}
              speed={1000}
              getApplicationNode={getApplicationNode}
              titleText="image detail"
              onEnter={handleEnter}
              mounted={isModalOpen}
              onExit={closeModal}
              dialogStyle={{
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                top: "initial",
                height:
                  typeof window !== "undefined"
                    ? window.innerHeight
                    : undefined,
              }}
              underlayColor={false}
              underlayClass={cx("modal", underlayStyles.className, {
                [inStyles.className]: hasEntered,
                [outStyles.className]: modalAction === "out",
              })}
            >
              <Carousel
                images={images}
                initialSlide={slideClickedIdx}
                onDismiss={closeModal}
              />
            </Modal>
          </>
        ) : null}
        {images.map((image, i) => (
          <Fragment key={i}>
            <animated.div
              style={{
                transform: props.xy.interpolate(
                  trans1.bind(this, galleryLayout.getPosition(i).depth)
                ),
                ...galleryLayout.getPosition(i).style,
              }}
              className="plx base"
            >
              <div
                style={{
                  ...adjustSize(
                    image.width,
                    image.height,
                    galleryLayout.getPosition(i).size
                  ),
                }}
                className="pos"
                onClick={() => openModal(i)}
              >
                <Image
                  id={i === 0 ? "first-image" : null}
                  alt=""
                  critical
                  fluid={image.thumb}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                  className="img"
                />
              </div>
            </animated.div>
            {isClient && isTouchDevice() ? null : (
              <animated.div
                style={{
                  transform: props.xy.interpolate(
                    trans1.bind(this, galleryLayout.getPosition(i).depth)
                  ),
                  ...galleryLayout.getPosition(i).style,
                }}
                onClick={() => openModal(i)}
                className="plx hover"
              >
                <div
                  style={{
                    ...adjustSize(
                      image.width,
                      image.height,
                      galleryLayout.getPosition(i).size
                    ),
                  }}
                  className="pos"
                >
                  <Image
                    className="img"
                    alt=""
                    fluid={image.thumb}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                  />
                  <span className="legend">{image.description}</span>
                </div>
              </animated.div>
            )}
          </Fragment>
        ))}
        <b className="clear" />
      </div>
    </>
  )
}

export const query = graphql`
  fragment gallery on ContentfulGallery {
    id
    images {
      id
      description
      file {
        url
        fileName
        contentType
        details {
          size
          image {
            width
            height
          }
        }
      }
      carousel: fluid(maxWidth: 1920, sizes: "calc(100% - 4rem)") {
        ...GatsbyContentfulFluid_withWebp
      }
      thumb: fluid(
        maxWidth: 960
        sizes: "(max-width: 768px): 100vw, calc(100vw - 500px)"
      ) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

import { useState, useEffect } from "react"

export default () => {
  const [data, setData] = useState({ beta: 0, gamma: 0 })
  let portrait = true

  const onDeviceMotion = event => {
    let beta = event.rotationRate.beta
    let gamma = event.rotationRate.gamma
    if (beta !== null && gamma !== null) {
      // motionStatus = 1
      portrait = window.innerHeight > window.innerWidth
      setData({ beta: portrait ? beta : gamma, gamma: portrait ? gamma : beta })
    }
  }

  const onDeviceOrientation = event => {
    let beta = event.beta
    let gamma = event.gamma
    if (beta !== null && gamma !== null) {
      // orientationStatus = 1
      portrait = window.innerHeight > window.innerWidth
      setData({ beta: portrait ? beta : gamma, gamma: portrait ? gamma : beta })
    }
  }

  useEffect(() => {
    const desktop = !navigator.userAgent.match(
      /(iPhone|iPod|iPad|Android|BlackBerry|BB10|mobi|tablet|opera mini|nexus 7)/i
    )
    const motionSupport = !!window.DeviceMotionEvent && !desktop
    const orientationSupport = !!window.DeviceOrientationEvent && !desktop

    if (motionSupport) {
      window.addEventListener("deviceMotion", onDeviceMotion, false)
    }
    if (orientationSupport) {
      window.addEventListener("deviceorientation", onDeviceOrientation, false)
    }

    return () => {
      if (motionSupport) {
        window.removeEventListener("deviceMotion", onDeviceMotion)
      }
      if (orientationSupport) {
        window.removeEventListener("deviceorientation", onDeviceOrientation)
      }
    }
  }, [])

  return data
}

import React from "react"
import cx from "classnames"
import styles from "./styles.scoped.css"

export default ({ className, children, ...props }) => (
  <h3 className={cx(className)}>
    <style jsx>{styles}</style>
    {children}
  </h3>
)

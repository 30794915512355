import React from "react"
import cx from "classnames"
import styles from "./styles.scoped.css"

export default ({ className, children, ...props }) => (
  <div className={cx(className, "container")} {...props}>
    <style jsx>{styles}</style>
    {children}
  </div>
)

/* global DocumentTouch */

// Based om Modernizrs detection
export default function isTouchDevice() {
  if (typeof window === "undefined") {
    return
  }

  var prefixes = " -webkit- -moz- -o- -ms- ".split(" ")
  var mq = function(query) {
    return window.matchMedia(query).matches
  }

  if (
    "ontouchstart" in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("")
  return mq(query)
}

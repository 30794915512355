import React, { Component, Fragment, useContext } from "react"
import arrowStyle from "./arrows.scoped.css"
import cx from "classnames"
import { LocaleContext } from "../_layout"

export const ArrowRight = props => {
  const { currentSlide, slideCount, className, ...rest } = props
  const disabled = currentSlide === slideCount - 1
  const locale = useContext(LocaleContext)

  return (
    <Fragment>
      <style jsx>{arrowStyle}</style>
      <button
        {...rest}
        className={cx(className, "nav-label nav-label--next")}
        disabled={disabled}
        tabIndex={disabled ? -1 : 1}
      >
        {locale === "zh" ? "下一步" : "Next"}
      </button>
    </Fragment>
  )
}

export const ArrowLeft = props => {
  const { currentSlide, slideCount, className, ...rest } = props
  const disabled = currentSlide === 0
  const locale = useContext(LocaleContext)

  return (
    <Fragment>
      <style jsx>{arrowStyle}</style>
      <button
        {...rest}
        className={cx(className, "nav-label nav-label--prev")}
        disabled={disabled}
        tabIndex={disabled ? -1 : 2}
      >
        {locale === "zh" ? "返回" : "Back"}
      </button>
    </Fragment>
  )
}

import values from "core-js/library/fn/object/values";

export default class GalleryLayout {
  constructor(images, positions) {
    this.images = images;
    this.nbPositions = values(positions.slots).length;
    this.slots = values(positions.slots);

    this.positions = this.getPositions(
      values(positions.slots),
      positions.offset
    );
    this.scenePadding = positions.offset * 1.5;
  }

  getPositions(positions, offset) {
    const calculatedPositions = [];

    for (let i = 0; i < this.images.length; i++) {
      const loopNb = Math.floor(i / this.nbPositions);
      const positionData = positions[i % this.nbPositions];

      const top =
        offset +
        positionData.top +
        (80 + this.getMaxPosition(this.slots)) * loopNb;

      calculatedPositions.push({
        ...positionData,
        top
      });
    }

    return calculatedPositions;
  }

  getMaxPosition(positions) {
    return positions.reduce((acc, pos) => (pos.top > acc ? pos.top : acc), 0);
  }

  getTop(i) {
    return this.positions[i % this.nbPositions].top;
  }

  getPosition(i) {
    return {
      style: {
        left: this.positions[i].left + "%",
        top: this.positions[i].top + "px"
      },
      size: this.positions[i].size * 1000, // target area (w*h of image in design)
      depth: this.positions[i].depth
    };
  }

  getSceneHeight() {
    return this.getMaxPosition(this.positions) + this.scenePadding;
  }
}

import React, { Component, createRef } from "react"
import { SpringSystem, MathUtil } from "rebound"
import cx from "classnames"
import Article from "../Article"
import styles from "./styles.scoped.css"

const mapValueInRangeCapped = (value, fromLow, fromHigh, toLow, toHigh) => {
  const result = MathUtil.mapValueInRange(
    value,
    fromLow,
    fromHigh,
    toLow,
    toHigh
  )
  return toHigh < toLow
    ? Math.max(toHigh, Math.min(toLow, result))
    : Math.min(toHigh, Math.max(toLow, result))
}

export default class ArticleModal extends Component {
  dragDistance = 0
  dragStartY = 0
  scrollStartY = 0

  constructor(props) {
    super(props)
    this.modal = createRef()
  }

  componentDidMount() {
    const springSystem = new SpringSystem()
    this.spring = springSystem.createSpring()
    this.spring.setOvershootClampingEnabled(true)
    this.spring.addListener({
      onSpringUpdate: this.handleSpringUpdate,
      onSpringAtRest: () => {
        if (this.dragDistance > 180) {
          this.props.onClose(undefined, true)
        }
      },
    })
  }

  componentWillUnmount() {
    this.spring.destroy()
  }

  handleTouchMove = ({ touches }) => {
    if (touches.length > 1) {
      return // ignore multitouch
    }

    const { scrollHeight, offsetHeight, scrollTop } = this.modal.current
    const dragDistance = this.dragStartY - touches[0].clientY
    this.dragDistance = dragDistance + scrollTop - scrollHeight + offsetHeight
    this.spring.setEndValue(Math.max(0, this.dragDistance))
  }

  handleTouchEnd = e => {
    if (this.dragDistance > 130) {
      this.spring.setEndValue(220)
      return
    }
    this.spring.setEndValue(0)
    this.dragDistance = 0
  }

  handleTouchStart = ({ touches }) => {
    this.dragDistance = 0
    this.dragStartY = touches[0].clientY
    this.scrollStartY = this.modal.current.scrollTop
  }

  handleSpringUpdate = spring => {
    const val = spring.getCurrentValue()
    const modal = this.modal.current
    const translate = mapValueInRangeCapped(val, 0, 200, 0, 160)
    const opacity = mapValueInRangeCapped(val, 60, 200, 1, 0)
    const scale = mapValueInRangeCapped(val, 10, 200, 1, 0.93)
    const radius = mapValueInRangeCapped(val, 15, 130, 0, 10)

    modal.style.overflow = translate !== 0 ? "hidden" : ""
    modal.style.transform = `scale(${scale}) translateY(-${translate}px)`
    modal.style.opacity = opacity
    modal.style.borderRadius = `${radius}px`
  }

  render() {
    return (
      <div
        ref={this.modal}
        onTouchMove={this.handleTouchMove}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        className={cx("article-modal")}
      >
        <style jsx>{styles}</style>
        <Article article={this.props.article} onClose={this.props.onClose} />
      </div>
    )
  }
}

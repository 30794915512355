import React from "react"
import styles from "./styles.scoped.css"
import Container from "../Container"
import H3 from "../H3"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
const breaks = require("remark-breaks")

export default ({ studios, title }) => (
  <Container>
    <style jsx>{styles}</style>
    <H3 className="title">{title}</H3>
    <div className="studio-grid">
      {studios.map((studio, i) => (
        <div key={i} className="studio">
          <div className="studio-body">
            <img alt="" width="52" src={studio.flag.file.url} />
            <h5 className="city">{studio.title}</h5>
            <div className="address">
              <ReactMarkdown
                plugins={[breaks]}
                source={studio.address.address}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  </Container>
)

export const query = graphql`
  fragment studios on ContentfulHomepage {
    studios {
      id
      title
      address {
        address
      }
      flag {
        file {
          url
          fileName
          contentType
        }
      }
    }
  }
`

import React, { Component, Fragment } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { articleShape } from "../Article"
import { markdown } from "markdown"

import Image from "gatsby-image"
import H3 from "../H3"
import Modal from "react-aria-modal"
import Container from "../Container"
import ArticleModal from "../ArticleModal"
import styles from "./styles.scoped.css"
import cx from "classnames"
import { resolve } from "styled-jsx/css"
import { LocaleContext } from "../_layout"

const inStyles = resolve`
  div {
    opacity: 1;
    transform: translateY(0);
  }`

const outStyles = resolve`
  div {
    transform: translateY(0);
  }`

const underlayStyles = resolve`
  div {
    opacity: 0;
    transition: opacity 450ms, transform 450ms;
    transform: translateY(80px);
  }`

export default class Articles extends Component {
  static contextType = LocaleContext

  static propTypes = {
    articles: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({ node: articleShape }))
        .isRequired.isRequired,
    }),
  }

  state = {
    isModalOpen: false,
    hasEntered: false,
    modalAction: "in",
  }

  componentDidMount() {
    this.setState({ isClient: true })
  }

  closeModal = (e, skipTransition) => {
    e && e.preventDefault()
    if (skipTransition) {
      this.setState({
        hasEntered: false,
        modalAction: "out",
        isModalOpen: false,
      })
    }

    this.setState(
      {
        hasEntered: false,
        modalAction: "out",
      },
      () => {
        window.history.pushState(
          {},
          "Geely Design",
          this.props.locale === "en-US" ? "/" : "/zh"
        )
        setTimeout(
          () =>
            this.setState({
              isModalOpen: false,
            }),
          500
        )
      }
    )
  }

  openModal = (e, article) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      isModalOpen: true,
      modalAction: "in",
      article,
    })

    const prefix = this.props.locale === "en-US" ? "" : "/zh"
    window.history.pushState(
      {},
      article.title,
      `${prefix}/articles/${article.slug}`
    )
  }

  handleEnter = () => {
    this.setState({
      hasEntered: true,
    })
  }

  getApplicationNode = () => {
    return document.getElementById("__next")
  }

  render() {
    return (
      <Fragment>
        <Container className="articles">
          <style jsx>{styles}</style>
          <H3>{this.props.title}</H3>
          <div className="row">
            {this.props.articles.edges.map(({ node: article }, i) => (
              <article
                onClick={e => this.openModal(e, article)}
                key={article.slug}
                className="article"
              >
                {article.featuredImage.thumbnail ? (
                  <div className="image">
                    <Image alt="" fluid={article.featuredImage.thumbnail} />
                  </div>
                ) : null}
                <h4 className="title">{article.title}</h4>
                {article.lead.lead ? (
                  <div
                    className="lead"
                    dangerouslySetInnerHTML={{
                      __html: markdown.toHTML(article.lead.lead),
                    }}
                  />
                ) : null}
                <Link
                  onClick={e => {
                    this.openModal(e, article)
                  }}
                  to={`/articles/${article.slug}`}
                  className="read-more"
                >
                  {this.context === "zh" ? "点击阅读" : "Read article"}
                </Link>
              </article>
            ))}
          </div>
          {underlayStyles.styles}
          {inStyles.styles}
          {outStyles.styles}
          {this.state.isClient ? (
            <Modal
              underlayColor={false}
              underlayClass={cx(underlayStyles.className, {
                [inStyles.className]: this.state.hasEntered,
                [outStyles.className]: this.state.modalAction == "out",
              })}
              focusDialog={true}
              getApplicationNode={this.getApplicationNode}
              titleText="Peter Horbury"
              mounted={this.state.isModalOpen}
              onEnter={this.handleEnter}
              onExit={this.closeModal}
            >
              <ArticleModal
                onClose={this.closeModal}
                article={this.state.article}
              />
            </Modal>
          ) : null}
        </Container>
      </Fragment>
    )
  }
}

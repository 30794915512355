import React from "react"
import Container from "../Container"
import ReactMarkdown from "react-markdown"
import styles from "./styles.scoped.css"
import { graphql } from "gatsby"

const handleClick = openInNewWindow => e => {
  if (!openInNewWindow) {
    return
  }

  e.preventDefault()
  const newWindow = window.open(e.target.href, "_blank")
  newWindow.opener = null
}

export default ({
  leadText,
  buttonLink,
  buttonLabel,
  logos = [],
  footerPrivacyPolicyLabel,
  footerPrivacyPolicyLink,
  footerCopyrightNotice,
  footerLinks = [],
}) => (
  <footer className="footer">
    <style jsx>{styles}</style>
    <Container>
      <div className="join">
        <div className="join-intro">
          <ReactMarkdown source={leadText} />
        </div>
        <a className="join-link" href={buttonLink}>
          {buttonLabel}
        </a>
      </div>
      <div className="logos">
        {logos.map((logo, i) =>
          logo.file.url ? (
            <span key={i} className="logo-link">
              <img className="logo" alt="" src={logo.file.url} />
            </span>
          ) : null
        )}
      </div>

      <div className="footer-menu">
        <span className="copyright">{footerCopyrightNotice}</span>
        <a
          className="left-footer-link footer-link"
          href={footerPrivacyPolicyLink}
        >
          {footerPrivacyPolicyLabel}
        </a>

        <a
          className="linkedin right-footer-link footer-link"
          href="https://www.linkedin.com/company/geely-design-global/"
        >
          LinkedIn
        </a>
        {footerLinks.map((link, i) => (
          <a
            key={i}
            className="footer-link"
            onClick={handleClick(link.openInNewWindow)}
            href={link.href}
          >
            {link.label}
          </a>
        ))}
      </div>
    </Container>
  </footer>
)

export const query = graphql`
  fragment footer on ContentfulHomepage {
    footerLeadText {
      footerLeadText
    }
    footerButtonLink
    footerButtonLabel
    footerPrivacyPolicyLabel
    footerPrivacyPolicyLink
    footerCopyrightNotice
    footerLogos {
      id
      file {
        url
        fileName
        contentType
      }
    }
    footerLinks {
      id
      label
      href
      openInNewWindow
    }
  }
`

export default {
  offset: 170,
  slots: {
    0: {
      top: 197,
      left: 29,
      size: 116,
      depth: 1.5,
    },
    1: {
      left: 76,
      top: 223,
      size: 100,
      depth: 0.5,
    },
    2: {
      left: 14,
      top: 480,
      size: 92,
      depth: 1.2,
    },
    3: {
      left: 65,
      top: 533,
      size: 92,
      depth: 0.8,
    },
    4: {
      left: 39,
      top: 768,
      size: 136,
      depth: 0.6,
    },
    5: {
      left: 20,
      top: 1021,
      size: 58,
      depth: 0.9,
    },
    6: {
      left: 75,
      top: 1051,
      size: 93,
      depth: 1.0,
    },
    7: {
      left: 39,
      top: 1197,
      size: 58,
      depth: 1.3,
    },
    8: {
      left: 75,
      top: 1305,
      size: 71,
      depth: 1.0,
    },
    9: {
      left: 24,
      top: 1413,
      size: 94,
      depth: 1.1,
    },
    10: {
      left: 65,
      top: 1627,
      size: 142,
      depth: 1.2,
    },
    11: {
      left: 29,
      top: 1818,
      size: 65,
      depth: 1.3,
    },
    12: {
      left: 19,
      top: 2088,
      size: 118,
      depth: 1.4,
    },
    13: {
      left: 76,
      top: 1912,
      size: 90,
      depth: 1,
    },
    14: {
      left: 65,
      top: 2187,
      size: 104,
      depth: 1,
    },
    15: {
      left: 29,
      top: 2422,
      size: 100,
      depth: 1.2,
    },
    16: {
      left: 81,
      top: 2463,
      size: 67,
      depth: 1.2,
    },
    17: {
      left: 35,
      top: 2671,
      size: 65,
      depth: 1.2,
    },
    18: {
      left: 70,
      top: 2727,
      size: 54,
      depth: 1.2,
    },
    19: {
      left: 80,
      top: 2919,
      size: 97,
      depth: 1.2,
    },
  },
};

import React from "react"
import styles from "./about-columns.scoped.css"
import Container from "../Container"

export default ({ children }) => (
  <Container>
    <div className="about">
      <style jsx>{styles}</style>
      {children}
    </div>
  </Container>
)

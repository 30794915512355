import React, { Component } from "react"
import cx from "classnames"
import slideStyle from "./slide.scoped.css"
import Image from "gatsby-image"
import { LocaleContext } from "../_layout"

export default class Slide extends Component {
  static contextType = LocaleContext

  shouldComponentUpdate(props) {
    return props.currentSlide !== this.props.currentSlide
  }

  render() {
    const {
      idx,
      image,
      currentSlide,
      handleEnter,
      handleLeave,
      onDismiss,
      isTransitioning,
      ...props
    } = this.props

    const isCurrent = idx === currentSlide

    return (
      <div
        {...props}
        className={cx("carouselSlide", {
          isTransitioning: isTransitioning,
        })}
      >
        <style jsx>{slideStyle}</style>
        <figure
          style={{
            width: `${(image.width / image.height) * 85}vh`,
          }}
          onMouseOver={() => handleEnter(idx, currentSlide)}
          onMouseOut={() => handleLeave(idx, currentSlide)}
          className="figure"
        >
          <Image alt="" className="carouselImage" fluid={image.carousel} />
          <figcaption className="caption">{image.description}</figcaption>
          {isCurrent ? (
            <button tabIndex="3" className="closeBtn" onClick={onDismiss}>
              {this.context === "zh" ? "关闭" : "close"}
            </button>
          ) : null}
        </figure>
      </div>
    )
  }
}

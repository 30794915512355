import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { LocaleContext } from "../_layout"

export const articleShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  contents: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        nodeType: PropTypes.string.isRequired,
      })
    ),
  }),
  featuredImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    sys: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}).isRequired

const Article = ({ onClose = () => {}, ...props }) => {
  const locale = useContext(LocaleContext)

  return (
    <div className="article container">
      <style jsx>{`
        .article {
          padding-left: 1.75rem;
          padding-right: 1.75rem;
          padding-bottom: 3.75rem;
          overflow: hidden;
          position: relative;
          box-sizing: content-box;
          color: white;
        }
        .article > :global(*) {
          max-width: 30em;
          margin-left: auto;
          margin-right: auto;
        }
        .container {
          margin: 0 auto;
          max-width: 46rem;
        }
        :global(.close-btn) {
          text-decoration: none;
          letter-spacing: 0.025rem;
          margin: 0 !important;
          padding: 1rem;
          color: inherit;
          display: inline-block;
        }
        .close-bar {
          background-color: rgba(22, 31, 41, 0.9);
          position: fixed;
          left: 0;
          right: 0;
          height: 3.625rem;
          text-align: right;
          margin: 0;
          max-width: 100% !important;
          z-index: 10;
        }
        .hero-img {
          display: block;
          margin-top: 3.75rem;
          margin-bottom: 3.75rem;
          max-width: 124%;
        }
        .hero-img > * {
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
        .title {
          font-weight: inherit;
          text-align: center;
          font-size: 26px;
          line-height: 32px;
          margin-top: 3.75rem;
          margin-bottom: 2rem;
        }
        .article-body :global(img) {
          display: block;
          margin-top: 3.75rem;
          margin-bottom: 2.25rem;
          max-width: 100%;
        }
        p {
          margin-top: 1em;
          margin-bottom: 1em;
          font-size: 1.125rem;
          line-height: 1.625rem;
        }
        @media (min-width: 768px) {
          .article-body :global(img) {
            max-width: 124%;
            margin-left: -12%;
            margin-right: -12%;
          }
        }
        @media (min-width: 1280px) {
          .article {
            font-size: 18px;
            line-height: 26px;
          }
        }
      `}</style>

      <div className="close-bar">
        <div className="container">
          <Link
            to={locale === "en" ? "/" : "/zh"}
            onClick={onClose}
            className="close-btn"
          >
            {locale === "zh" ? "关闭" : "CLOSE"}
          </Link>
        </div>
      </div>

      <div className="hero-img">
        <Image fluid={props.article.featuredImage.large} />
      </div>

      <div className="article-body">
        <h2 className="title">
          {props.article.title}
          <br />
          {props.article.subtitle}
        </h2>

        <ReactMarkdown source={props.article.body.body} />
      </div>
    </div>
  )
}

export default Article

export const query = graphql`
  fragment article on ContentfulArticle {
    id
    node_locale
    title
    subtitle
    slug
    lead {
      lead
    }
    body {
      body
    }
    featuredImage {
      thumbnail: fluid(maxWidth: 480, maxHeight: 270) {
        ...GatsbyContentfulFluid_withWebp
      }
      large: fluid(maxWidth: 736) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

export default {
  offset: 90,
  slots: {
    0: {
      top: 99,
      left: 28,
      size: 29,
      depth: 1.5,
    },
    1: {
      left: 69,
      top: 247,
      size: 38,
      depth: 0.5,
    },
    2: {
      left: 20,
      top: 314,
      size: 15,
      depth: 1.2,
    },
    3: {
      left: 60,
      top: 434,
      size: 35,
      depth: 0.8,
    },
    4: {
      left: 80,
      top: 541,
      size: 15,
      depth: 0.6,
    },
    5: {
      left: 39,
      top: 654,
      size: 36,
      depth: 0.9,
    },
  },
};

import React, { Component, createRef } from "react"
import { SpringSystem, MathUtil } from "rebound"
import Masthead from "../Masthead"
import cx from "classnames"
import styles from "./styles.scoped.css"
import { graphql } from "gatsby"
import { LocaleContext } from "../_layout"

const mapValueInRangeCapped = (value, fromLow, fromHigh, toLow, toHigh) => {
  const result = MathUtil.mapValueInRange(
    value,
    fromLow,
    fromHigh,
    toLow,
    toHigh
  )
  return toHigh < toLow
    ? Math.max(toHigh, Math.min(toLow, result))
    : Math.min(toHigh, Math.max(toLow, result))
}

export class Preloader extends Component {
  state = {
    hasLoaded: false,
  }

  constructor(props) {
    super(props)
    this.video = createRef()
  }

  componentDidMount() {
    this.timeout = setTimeout(this.handleReady, 3 * 1000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  handleReady = () => {
    this.props.onLoad()
    this.setState({ hasLoaded: true })
  }

  render() {
    return this.state.hasLoaded ? null : (
      <video
        ref={this.video}
        src={this.props.video.file.url}
        onCanPlay={this.handleReady}
        onCanPlayThrough={this.handleReady}
        poster="/static/video-placeholder.jpg"
        style={{
          width: 0,
          height: 0,
          position: "absolute",
        }}
      />
    )
  }
}

export default class VideoIntro extends Component {
  static contextType = LocaleContext

  state = {
    isFixed: true,
    isMounted: false,
  }

  constructor(props) {
    super(props)
    this.container = createRef()
    this.video = createRef()
    this.header = createRef()
    this.footer = createRef()
  }

  updateVideoStyle = val => {
    const video = this.video.current
    const width = video.offsetWidth
    const height = video.offsetHeight
    const crop = mapValueInRangeCapped(val, 0, 0.3, 0, width * 0.1)
    const translate = mapValueInRangeCapped(val, 0.2, 0.6, 0, 160)
    const opacity = mapValueInRangeCapped(val, 0.4, 0.7, 1, 0.2)

    video.style.clip =
      "rect(" +
      [0, width - crop, height, crop].map(w => Math.round(w) + "px").join(",") +
      ")"
    video.style.clipPath = `inset(0 ${crop}px 0 ${crop}px)`
    video.style.transform = "translateY(-" + translate + "px)"
    video.style.opacity = opacity
  }

  updateFooterStyle = val => {
    const translate = mapValueInRangeCapped(val, 0, 0.4, 0, 40)
    const footer = this.footer.current
    const opacity = mapValueInRangeCapped(val, 0.2, 0.5, 1, 0)
    footer.style.transform = "translateY(-" + translate + "px)"
    footer.style.opacity = opacity
  }

  updateHeaderStyle = val => {
    const translate = mapValueInRangeCapped(val, 0, 0.2, 0, 100)
    const header = this.header.current
    header.style.transform = "translateY(-" + translate + "px)"
  }

  handleSpringUpdate = spring => {
    const val = spring.getCurrentValue()
    this.updateVideoStyle(val)
    this.updateHeaderStyle(val)
    this.updateFooterStyle(val)
  }

  handleScroll = e => {
    const pos = window.scrollY / window.innerHeight
    this.spring.setEndValue(pos)
  }

  componentDidMount() {
    this.initSpring()
    window.addEventListener("scroll", this.handleScroll)
    this.setState(
      {
        isMounted: true,
      },
      () => setTimeout(() => this.setState({ mountDone: true }), 2000)
    )
  }

  componentWillUnmount() {
    this.spring.destroy()
    window.removeEventListener("scroll", this.handleScroll)
  }

  initSpring() {
    const springSystem = new SpringSystem()
    this.spring = springSystem.createSpring(70, 20)
    this.spring.setOvershootClampingEnabled(false)
    this.spring.addListener({
      onSpringUpdate: this.handleSpringUpdate,
    })
  }

  render() {
    return (
      <>
        <style jsx>{styles}</style>
        <div
          ref={this.container}
          className={cx("video-intro", {
            "is-mounted": this.state.isMounted,
            "mount-done": this.state.mountDone,
          })}
        >
          <div className="header">
            <Masthead locale={this.props.locale} innerRef={this.header} />
          </div>
          <div className="video-wrap">
            <video
              className="video"
              ref={this.video}
              alt=""
              poster="/static/video-placeholder.jpg"
              src={this.props.video.file.url}
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
          <div
            style={{ display: this.state.isFixed ? null : "none" }}
            className="footer"
            ref={this.footer}
          >
            <p className="cto">
              {this.context === "zh" ? "向下滑动" : "scroll down"}
            </p>
          </div>
        </div>
      </>
    )
  }
}
export const query = graphql`
  fragment video on ContentfulHomepage {
    video {
      file {
        url
        contentType
      }
    }
  }
`

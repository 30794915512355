export default {
  offset: 200,
  slots: {
    0: {
      top: 310,
      left: 18,
      size: 280,
      depth: 1.5,
    },
    1: {
      left: 47,
      top: 330,
      size: 231,
      depth: 0.5,
    },
    2: {
      left: 72,
      top: 620,
      size: 226,
      depth: 1.2,
    },
    3: {
      left: 79,
      top: 250,
      size: 230,
      depth: 0.8,
    },
    4: {
      left: 17,
      top: 1080,
      size: 325,
      depth: 0.6,
    },
    5: {
      left: 37,
      top: 720,
      size: 135,
      depth: 0.9,
    },
    6: {
      left: 85,
      top: 1140,
      size: 168,
      depth: 1.0,
    },
    7: {
      left: 63,
      top: 930,
      size: 139,
      depth: 1.3,
    },
    8: {
      left: 47,
      top: 1260,
      size: 222,
      depth: 1.0,
    },
    9: {
      left: 20,
      top: 1610,
      size: 226,
      depth: 1.1,
    },
    10: {
      left: 53,
      top: 1650,
      size: 167,
      depth: 1.2,
    },
    11: {
      left: 85,
      top: 1490,
      size: 215,
      depth: 1.3,
    },
    12: {
      left: 17,
      top: 1930,
      size: 206,
      depth: 1.4,
    },
    13: {
      left: 43,
      top: 2080,
      size: 280,
      depth: 1,
    },
    14: {
      left: 85,
      top: 1830,
      size: 119,
      depth: 1,
    },
    15: {
      left: 72,
      top: 2145,
      size: 230,
      depth: 1.2,
    },
    16: {
      left: 15,
      top: 2300,
      size: 137,
      depth: 1.2,
    },
  },
};
